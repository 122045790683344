import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Container } from "../../styles/UI";

import Layout from "../../components/Layout";
import StyledLink from "../../DesignSystem/StyledLink";
import QuestionWrapper from "../../components/QuestionWrapper";

import ProdutosHome from "../home/produtos";
import ApresentacaoHome from "../home/apresentacao";

import taxcelBgLogo from "../../images/home/logo-negative.svg";

import * as S from "./style";
import Seo from "../../components/Seo";

export default function Produtos({ questions }) {
  return (
    <Layout>
      <Seo title="Produtos" />
      <S.Hero $clip={true}>
        <S.Container>
          <div className="cta">
            <h1>Soluções para Analytics e Compliance Fiscal</h1>
            <p>
              Planos sob medida para um departamento <br />
              fiscal mais proativo e estratégico.
            </p>
          </div>
          <div className="illustration">
            <StaticImage
              src="../../images/hero-woman2.png"
              quality={100}
              className="hero-illustration"
              loading="eager"
              height={460}
              alt="imagem de uma mulher sorrindo"
            />
            <img src={taxcelBgLogo} alt="" className="bg-logo" />
          </div>
        </S.Container>
      </S.Hero>
      <ProdutosHome paralax border />
      <S.SobMedida>
        <Container>
          <h3>
            Planos sob medida para um departamento fiscal mais proativo e
            estratégico
          </h3>
          <p>Teste 7 dias grátis</p>
          <StyledLink to="/planos" arrow>
            Conheça nossos planos
          </StyledLink>
        </Container>
      </S.SobMedida>
      <S.Faq>
        <Container>
          <h3>Dúvidas frequentes</h3>
          <QuestionWrapper questions={questions} />
        </Container>
      </S.Faq>
      <ApresentacaoHome white />
    </Layout>
  );
}
