import { graphql } from "gatsby";
import React from "react";
import Produtos from "../pagesFiles/produtos";

export default function produtos({ data }) {
  return <Produtos questions={data.allFaqJson.nodes[0].produtos} />;
}

export const query = graphql`
  {
    allFaqJson {
      nodes {
        produtos {
          body
          id
          question
        }
      }
    }
  }
`;
