import styled, { css } from "styled-components";
import { Container } from "../../../styles/UI";

export const Produtos = styled.section`
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: ${p => p.$white ? "white" : p.theme.bgGray};
  position: relative;

  ${(p) =>
    p.$border &&
    css`
      &:after {
        display: block;
        content: "";
        height: 8px;
        width: 80%;
        border-radius: 0 0 0 16px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: ${(p) => p.theme.laranja};
        transform: translateY(100%);

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          width: calc(50% - 16px);
        }
      }
    `}

  .paralax {
    display: none;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding-top: 120px;
    padding-bottom: 160px;

    ${Container} {
      position: relative;
    }

    .paralax {
      display: block;
      position: absolute;
      left: -372px;
      top: -212px;
    }
  }

  .cards {
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
    }
  }

  h2 {
    margin-bottom: 80px;
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;
    /* or 123% */

    text-align: center;
    letter-spacing: -1px;
    background: ${(p) => p.theme.degradeMisto2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    line-height: 42px;

    span {
      font-size: 50px;
    }
  }
`;
